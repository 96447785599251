// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$register-frontend-primary: mat.define-palette(mat.$indigo-palette);
$register-frontend-accent: mat.define-palette(
	mat.$pink-palette,
	A200,
	A100,
	A400
);

// The warn palette is optional (defaults to red).
$register-frontend-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$register-frontend-theme: mat.define-light-theme(
	(
		color: (
			primary: $register-frontend-primary,
			accent: $register-frontend-accent,
			warn: $register-frontend-warn
		)
	)
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($register-frontend-theme);

:root {
	/* COLORS */
	--color-black: #000000;
	--color-white: #ffffff;

	--color-gray-100: #f6f6f9;
	--color-gray-200: #f1f1f3;
	--color-gray-300: #e2e8f0;
	--color-gray-400: #a0aec0;
	--color-gray-500: #2d3748;

	--color-red-100: #fff5f5;
	--color-red-200: #feb2b2;
	--color-red-300: #f56565;
	--color-red-400: #9b2c2c;
	--color-red-500: #63171b;

	--color-red-ifood-500: #ea1d2c;
	--color-red-ifood-600: #c61925;

	--color-orange-100: #fffaf0;
	--color-orange-200: #fbd38d;
	--color-orange-300: #ff9114;
	--color-orange-400: #9c4221;
	--color-orange-500: #652b19;

	--color-green-100: #f0fff4;
	--color-green-200: #9ae6b4;
	--color-green-300: #48bb78;
	--color-green-400: #276749;
	--color-green-500: #1c4532;

	--color-blue-100: #ebf8ff;
	--color-blue-200: #90cdf4;
	--color-blue-300: #0098fc;
	--color-blue-400: #2c5282;
	--color-blue-500: #1a365d;

	/* FONT-SIZE */
	--font-size-xs: 0.75rem;
	--font-size-sm: 0.875rem;
	--font-size-md: 1rem;
	--font-size-lg: 1.125rem;
	--font-size-xl: 1.25rem;
	--font-size-2xl: 1.5rem;
	--font-size-3xl: 1.875rem;

	/* LINE-HEIGHT */
	--line-height-xs: 1;
	--line-height-md: 1.5;
	--line-height-lg: 2;

	/* BREAKPOINTS */
	--breakpoints-sm: 30em;
	--breakpoints-md: 48em;
	--breakpoints-lg: 62em;
	--breakpoints-xl: 80em;

	/* SPACE */
	--space-xs: 0.5rem;
	--space-sm: 1rem;
	--space-md: 1.5rem;
	--space-lg: 2rem;
	--space-xl: 2.5rem;

	/* SIZES */
	--size-sm: 2rem;
	--size-md: 3rem;
	--size-lg: 4rem;

	/* BORDER-RADIUS */
	--radius-base: 0.25rem;
	--radius-full: 50%;

	/* Z-INDEX */
	--zindex-hide: -1;
	--zindex-dropdown: 1000;
	--zindex-sticky: 1100;
	--zindex-banner: 1200;
	--zindex-overlay: 1300;
	--zindex-modal: 1400;
	--zindex-popover: 1500;
	--zindex-toast: 1700;
	--zindex-tooltip: 1800;

	/* SIZES */
	--shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
		rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px,
		rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
		rgb(60 66 87 / 8%) 0px 2px 5px 0px;

	/* FONT-FAMILY */
	--font-family: Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
}

/* You can add global styles to this file, and also import other style files */
html,
body {
	margin: 0;
	padding: 0;
	font-family: Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
	scroll-behavior: smooth;
}
html,
body {
	margin: 0;
	font-family: Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
	font-style: normal;
}

h1 {
	font-family: Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 30px;
	color: #2b2b2b;
}

h2 {
	font-family: Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 23px;
	color: #2b2b2b;
}

h3 {
	font-weight: 400;
	font-size: 16px;
	line-height: 23px;
	color: #9d9d9d;
}

label {
	font-size: 14px;
	line-height: 16px;
	font-weight: 600;
}
.form {
	height: 60%;
	padding-top: 10%;
	margin-bottom: 35px;
	width: 60%;
}
.container {
	display: flex;
	flex-direction: row;
	margin: 0;
	padding: 0;
}

label {
	display: flex;
	margin-bottom: 5px;
}

@media screen and (max-width: 915px) {
	.container {
		width: 100%;
		margin-top: 20px;
		align-items: center;
		position: relative;
	}

	.form {
		width: 80%;
		padding-top: 0;
	}
}

// .fade-up-animation{
//   animation: up 2s ease-in-out;

//   @keyframes up{
//       0%{
//           opacity: 0;
//           transform: translateY(100%);
//       }
//       100%{
//           opacity: 100%;
//           transform: translateY(0%);
//       }
//   }
// }

.spinner {
	animation: rotate 2s linear infinite;
	z-index: 2;
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -25px 0 0 -25px;
	width: 50px;
	height: 50px;
	stroke: #fff;
}
.path {
	stroke: rgb(218, 228, 238);
	stroke-linecap: round;
	animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
	100% {
		transform: rotate(360deg);
	}
}

@keyframes dash {
	0% {
		stroke-dasharray: 1, 150;
		stroke-dashoffset: 0;
	}
	50% {
		stroke-dasharray: 90, 150;
		stroke-dashoffset: -35;
	}
	100% {
		stroke-dasharray: 90, 150;
		stroke-dashoffset: -124;
	}
}

anota-button {
	button {
		max-height: 48px;
		min-height: 48px;
		min-width: 150px;

		padding: 0 50px;

		@media screen and (max-width: 915px) {
			max-width: 100%;
			min-width: 100%;
		}
	}
}
.form-group {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	position: relative;

	margin-bottom: var(--space-sm);

	> div {
		display: flex;
		align-items: center;

		label {
			margin: 0;
		}
	}

	&.invalid {
		input,
		select {
			border: 1px solid red;
		}

		label {
			color: red;
		}

		.error-message {
			color: red;
			font-weight: 400;
			font-size: var(--font-size-sm);
		}
	}

	&.w-full {
		input {
			width: calc(100% - 28px);
		}
	}

	&.h-sm {
		input {
			height: 40px !important;
		}
	}

	&.h-md {
		input {
			height: 40px !important;
		}
	}

	&.h-md {
		input {
			height: 40px !important;
		}
	}

	label {
		font-size: var(--font-size-sm);
		margin-bottom: var(--space-xs);
		font-weight: 600;
	}

	input:not([type='checkbox']) {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		height: 40px;
	}

	input {
		outline: none;
		border: var(--color-gray-300) 1px solid;

		border-radius: var(--radius-base);
		background: var(--color-white);

		padding: 0 14px;

		transition: all ease-in 0.04s;

		&:disabled {
			background-color: var(--color-gray-100);
			cursor: not-allowed;
		}
		&:focus {
			border: #0098fc 1px solid;
		}
	}

	select {
		// A reset of styles, including removing the default dropdown arrow

		height: 40px;

		outline: none;
		border: none;
		border-radius: var(--radius-base);
		padding-left: var(--space-md);
		color: var(--color-gray-500);

		box-shadow: var(--shadow);

		transition: all ease-in 0.04s;

		width: 100%;

		cursor: pointer;
		display: grid;
		grid-template-areas: 'select';
		grid-area: select;
		align-items: center;

		-moz-appearance: none; /* Firefox */
		-webkit-appearance: none; /* Safari and Chrome */
		appearance: none;

		background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
			no-repeat;
		background-position: calc(100% - 0.75rem) center !important;

		// create a custom arrow
		&:after {
			content: '';
			position: absolute;
			top: 50%;
			right: var(--space-md);
			transform: translateY(-50%);
			width: 0;
			height: 0;
			border-left: var(--space-md) solid transparent;
			border-right: var(--space-md) solid transparent;
			border-top: var(--space-md) solid var(--color-gray-300);
		}

		&:disabled {
			background-color: var(--color-gray-100);
			cursor: not-allowed;
		}
	}

	.error-message {
		margin-top: var(--space-xs);
		color: var(--color-red-400);

		font-weight: bold;
	}
}

textarea {
	height: 40px;

	outline: none;
	border: 0;
	border-radius: var(--radius-base);
	background: var(--color-white);

	padding: var(--space-md);

	box-shadow: var(--shadow);

	transition: all ease-in 0.04s;

	&:disabled {
		background-color: var(--color-gray-100);
		cursor: not-allowed;
	}
}

/* FULL WIDTH */
.w-full {
	width: 100%;
}

/* UNDERLINE */
.underline {
	position: relative;

	&::before {
		content: '';
		position: absolute;
		bottom: 0;
		right: 0;
		width: 0;
		height: 2px;
		background-color: var(--color-blue-500);
		transition: width 0.6s cubic-bezier(0.25, 1, 0.5, 1);
	}
}

@media (hover: hover) and (pointer: fine) {
	.underline:hover::before {
		left: 0;
		right: auto;
		width: 100%;
	}
}

/* ALERT */

.alert {
	display: flex;
	align-items: center;
	justify-content: space-between;

	padding: var(--space-sm);
	border-radius: var(--radius-base);

	&.alert-info {
		border: 1px solid var(--color-blue-200);
		background: var(--color-blue-100);
	}
}

/* FORM */
form {
	.row {
		display: flex;
		flex-wrap: wrap;

		& > * {
			margin: 0 var(--space-sm);

			&:first-child {
				margin-left: 0;
				margin-right: var(--space-sm);
			}

			&:last-child {
				margin-left: var(--space-sm);
				margin-right: 0;
			}

			@media only screen and (max-width: 768px) {
				padding: 0;
				margin: 0;
				width: 100% !important;
			}
		}

		.col-sm,
		.col-md,
		.col-lg {
			@media only screen and (max-width: 768px) {
				padding: 0;
				margin: 0;
				width: 100% !important;
			}
		}

		.col-sm {
			width: calc(25% - var(--space-sm));
		}

		.col-md {
			width: calc(50% - var(--space-sm));
		}

		.col-lg {
			width: calc(75% - var(--space-sm));
		}

		.large-xl {
			width: calc(100% - var(--space-sm));
		}
	}
}
